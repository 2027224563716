<template>
  <router-view />
</template>

<style lang="less">
#app {
  text-align: center;
  max-width: 500px;
  margin: 0 auto 0;
}
</style>
