import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/index',
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../views/Index.vue"),
    redirect: '/chat',
    children: [
      { path: '/chat', name: 'chat', component: () => import("../views/Chat.vue") },
      { path: '/friend', name: 'friend', component: () => import("../views/Friend.vue") },
      { path: '/file', name: 'file', component: () => import("../views/File.vue") },
      { path: '/info', name: 'info', component: () => import("../views/Info.vue") },
      { path: '/session', name: 'session', component: () => import("../views/Session.vue") },
      { path: '/userInfo', name: 'userInfo', component: () => import("../views/UserInfo.vue") },
      { path: '/newFriend', name: 'newFriend', component: () => import("../views/NewFriend.vue") },
    ]
  },
  { path: '/short', name: 'short', component: () => import("../views/Short.vue") },
  { path: '/disconn', name: 'disconn', component: () => import("../views/Disconn.vue") },
  { path: '/tip', name: 'tip', component: () => import("../views/Black.vue") },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   // component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory("/"),
  routes,
});

export default router;